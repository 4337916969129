/**
 * [科室划分]
 * @type {Array}
 */
const getClinicList = [
    {
        clinic_no: 1,
        clinic_name: '妇科',
        second_clinic: [
            {
                second_clinic_no: '',
                second_clinic_name: '妇科'
            }
        ]
    },
    {
        clinic_no: 2,
        clinic_name: '儿科',
        second_clinic: [
            {
                second_clinic_no: 'fb',
                second_clinic_name: '新生儿科'
            },
            {
                second_clinic_no: 'fa',
                second_clinic_name: '小儿科'
            }
        ]
    },
    {
        clinic_no: 3,
        clinic_name: '内科',
        second_clinic: [
            {
                second_clinic_no: 'ai',
                second_clinic_name: '感染科'
            },
            {
                second_clinic_no: 'ah',
                second_clinic_name: '血液病科'
            },
            {
                second_clinic_no: 'ag',
                second_clinic_name: '风湿免疫科'
            },
            {
                second_clinic_no: 'af',
                second_clinic_name: '内分泌与代谢科'
            },
            {
                second_clinic_no: 'ae',
                second_clinic_name: '肾内科'
            },
            {
                second_clinic_no: 'ad',
                second_clinic_name: '消化内科'
            },
            {
                second_clinic_no: 'ac',
                second_clinic_name: '神经内科'
            },
            {
                second_clinic_no: 'ab',
                second_clinic_name: '心血管内科'
            },
            {
                second_clinic_no: 'aa',
                second_clinic_name: '呼吸内科'
            }
        ]
    },
    {
        clinic_no: 4,
        clinic_name: '皮肤性病科',
        second_clinic: [
            {
                second_clinic_no: 'hb',
                second_clinic_name: '性病科'
            },
            {
                second_clinic_no: 'ha',
                second_clinic_name: '皮肤科'
            }
        ]
    },
    {
        clinic_no: 6,
        clinic_name: '营养科',
        second_clinic: [
            {
                second_clinic_no: '',
                second_clinic_name: '营养科'
            }
        ]
    },
    {
        clinic_no: 7,
        clinic_name: '骨伤科',
        second_clinic: [
            {
                second_clinic_no: 'cc',
                second_clinic_name: '创伤科'
            },
            {
                second_clinic_no: 'cb',
                second_clinic_name: '关节科'
            },
            {
                second_clinic_no: 'ca',
                second_clinic_name: '脊柱科'
            }
        ]
    },
    {
        clinic_no: 8,
        clinic_name: '男科',
        second_clinic: [
            {
                second_clinic_no: '',
                second_clinic_name: '男科'
            }
        ]
    },
    {
        clinic_no: 9,
        clinic_name: '外科',
        second_clinic: [
            {
                second_clinic_no: 'bj',
                second_clinic_name: '甲状腺乳腺外科'
            },
            {
                second_clinic_no: 'bi',
                second_clinic_name: '普外科'
            },
            {
                second_clinic_no: 'bh',
                second_clinic_name: '肛肠科'
            },
            {
                second_clinic_no: 'bg',
                second_clinic_name: '泌尿外科'
            },
            {
                second_clinic_no: 'bf',
                second_clinic_name: '康复科'
            },
            {
                second_clinic_no: 'be',
                second_clinic_name: '烧伤科'
            },
            {
                second_clinic_no: 'bd',
                second_clinic_name: '肝胆外科'
            },
            {
                second_clinic_no: 'bc',
                second_clinic_name: '神经外科'
            },
            {
                second_clinic_no: 'bb',
                second_clinic_name: '心脏与血管外科'
            },
            {
                second_clinic_no: 'ba',
                second_clinic_name: '胸外科'
            }
        ]
    },
    {
        clinic_no: 11,
        clinic_name: '肿瘤及防治科',
        second_clinic: [
            {
                second_clinic_no: 'md',
                second_clinic_name: '肿瘤中医科'
            },
            {
                second_clinic_no: 'mc',
                second_clinic_name: '介入与放疗中心'
            },
            {
                second_clinic_no: 'mb',
                second_clinic_name: '肿瘤外科'
            },
            {
                second_clinic_no: 'ma',
                second_clinic_name: '肿瘤内科'
            }
        ]
    },
    {
        clinic_no: 12,
        clinic_name: '中医科',
        second_clinic: [
            {
                second_clinic_no: 'oe',
                second_clinic_name: '中医儿科'
            },
            {
                second_clinic_no: 'od',
                second_clinic_name: '中医男科'
            },
            {
                second_clinic_no: 'oc',
                second_clinic_name: '中医妇科'
            },
            {
                second_clinic_no: 'ob',
                second_clinic_name: '中医外科'
            },
            {
                second_clinic_no: 'oa',
                second_clinic_name: '中医内科'
            }
        ]
    },
    {
        clinic_no: 13,
        clinic_name: '口腔颌面科',
        second_clinic: [
            {
                second_clinic_no: '',
                second_clinic_name: '口腔颌面科'
            }
        ]
    },
    {
        clinic_no: 14,
        clinic_name: '耳鼻咽喉科',
        second_clinic: [
            {
                second_clinic_no: 'jc',
                second_clinic_name: '口腔颌面科'
            },
            {
                second_clinic_no: 'jb',
                second_clinic_name: '鼻科'
            },
            {
                second_clinic_no: 'ja',
                second_clinic_name: '耳科'
            }
        ]
    },
    {
        clinic_no: 15,
        clinic_name: '眼科',
        second_clinic: [
            {
                second_clinic_no: '',
                second_clinic_name: '眼科'
            }
        ]
    },
    {
        clinic_no: 16,
        clinic_name: '整型美容科',
        second_clinic: [
            {
                second_clinic_no: '',
                second_clinic_name: '整型美容科'
            }
        ]
    },
    {
        clinic_no: 17,
        clinic_name: '精神心理科',
        second_clinic: [
            {
                second_clinic_no: 'nb',
                second_clinic_name: '心理科'
            },
            {
                second_clinic_no: 'na',
                second_clinic_name: '精神科'
            }
        ]
    },
    {
        clinic_no: 21,
        clinic_name: '产科',
        second_clinic: [
            {
                second_clinic_no: '',
                second_clinic_name: '产科'
            }
        ]
    },
    {
        clinic_no: 22,
        clinic_name: '报告解读科',
        second_clinic: [
            {
                second_clinic_no: 'qi',
                second_clinic_name: '预防保健科'
            },
            {
                second_clinic_no: 'qh',
                second_clinic_name: '体检中心'
            },
            {
                second_clinic_no: 'qg',
                second_clinic_name: '麻醉科'
            },
            {
                second_clinic_no: 'qf',
                second_clinic_name: '超声科'
            },
            {
                second_clinic_no: 'qe',
                second_clinic_name: '心电图科'
            },
            {
                second_clinic_no: 'qd',
                second_clinic_name: '病理科'
            },
            {
                second_clinic_no: 'qc',
                second_clinic_name: '内镜科'
            },
            {
                second_clinic_no: 'qb',
                second_clinic_name: '放射科'
            },
            {
                second_clinic_no: 'qa',
                second_clinic_name: '检验科'
            }
        ]
    },
]

module.exports = {
    getClinicList,
}

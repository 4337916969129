<template>
  <div class="doctor_list">
    <div class="header">
      <van-search v-model="searchValue" right-icon left-icon="" @search="onSearch" placeholder="输入症状、疾病、医院、科室、医生名">
        <div slot="right-icon" class="search_img" @click="onSearch">
          <img src="../assets/img/seach_icon.png" alt="">
        </div>
      </van-search>
      <div class="dropdown">
        <van-dropdown-menu>
          <van-dropdown-item :title="handleCity" ref="city">
            <van-tree-select :items="itemsCity" :active-id.sync="activeId" @click-nav="onClickNav" @click-item="onClickItem" :main-active-index.sync="mainActiveIndex" />
          </van-dropdown-item>
          <van-dropdown-item :title="department" ref="client">
            <van-tree-select :items="departmentList" :active-id.sync="departmentId" @click-nav="onClickLeft" @click-item="onClickRight" :main-active-index.sync="departmentIndex" />
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
    </div>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="list">
        <div class="card" v-for="item in doctor_list" :key="item.id" @click="onDetail(item.id)">
          <div class="icon">
            <img :src="item.image" alt="">
          </div>
          <div class="info">
            <div class="txt1"><span>{{item.name}}</span> {{item.clinic_name}} {{item.title}}</div>
            <div class="txt2">{{item.hospital_name}}</div>
            <div class="txt3">擅长：{{item.good_at}}</div>
            <div class="txt4">{{item.purchase_num}}人已选择购买</div>
          </div>
          <div class="remark">
            <div class="money">¥<span>{{item.price}}</span>/次</div>
            <!-- <div class="follow" v-if="false">
            <img src="../assets/img/follow.png" alt="">
            <p>关注</p>
          </div>
          <div class="follow" v-else>
            <img src="../assets/img/followed.png" alt="">
          </div> -->
          </div>
        </div>
      </div>
    </van-list>
    <v-login :is_login="is_login" @onLoginSuccess="onLoginSuccess" />
  </div>
</template>
<script>
import vLogin from "@/components/login";
import provinces from '../utils/province';
import globalData from '../utils/department';
import { existsSync } from 'fs';
export default {
  data() {
    return {
      is_login: false,
      searchValue: '',
      handleCity: '全国',
      itemsCity: [], //全国省市列表
      mainActiveIndex: null,
      province: '', //省
      city: '', // 市
      activeId: null,
      department: '科室',
      departmentList: [], //科室
      departmentIndex: null,
      departmentId: null,
      loading: false,
      finished: false,
      page: 1,
      doctor_list: [],
      start_num: 0,
      count: 10
    }
  },
  components: {
    vLogin,
  },
  created() {
    this.formatting();
    // // console.log(this.$route.query)
    // if (this.$route.query.type == 1) { //搜索接口
    //   this.searchValue = this.$route.query.query_text;
    //   this.onSearchList();
    // } else {
    //   this.onFindDocList(this.$route.query);
    // }
    let loginData = JSON.parse(localStorage.getItem("userData")) || "";
    if (loginData) {
      this.$store.dispatch('setPrice');
    }
  },
  methods: {
    onLoginSuccess() {

    },
    onLoad() {
      let loginData = JSON.parse(localStorage.getItem("userData")) || "";
      if (loginData) {
        if (this.$route.query.type == 1) { //搜索接口
          this.searchValue = this.$route.query.query_text;
          this.onSearchList();
        } else {
          this.onFindDocList(this.$route.query);
        }
      } else {
        this.is_login = true;
      }
    },
    onLoginSuccess() {
      this.$store.dispatch('setPrice');
      this.is_login = false;
      if (this.$route.query.type == 1) { //搜索接口
        this.searchValue = this.$route.query.query_text;
        this.onSearchList();
      } else {
        this.onFindDocList(this.$route.query);
      }
    },
    // 搜索接口
    onSearchList() {
      this.loading = true;
      let params = {
        query_text: (this.departmentId || '') + ',' + this.searchValue,
        page: this.page,
        province: this.province,
        city: this.city
      }
      // console.log('this========>', this);
      this.$http('post', '/cooperation/server/doctor/search_doctor/', params).then(res => {
        // console.log('搜索', res);
        this.loading = false;
        if (res.data.error == 0) {
          this.page += 1;
          for (let i = 0; i < res.data.doctors.length; i++) {
            res.data.doctors[i].price = ((res.data.doctors[i].price / 100) * (1 + this.$store.state.floatRate)).toFixed(2);
          }
          this.doctor_list = this.doctor_list.concat(res.data.doctors);
          // this.doctor_list.forEach(item => {
          //   item.price = ((item.price / 100) * (1 + this.$store.state.floatRate)).toFixed(2);
          // })
          if (!res.data.doctors.length) {
            this.finished = true;
          }
        }

      })
    },
    // 找医生
    onFindDocList(query) {
      this.loading = true;
      // console.log(query)
      this.department = query.query_text;
      this.departmentId = query.query_text;
      let params = {
        clinic_no: query.clinic_no,
        count: 10,
        start_num: this.start_num,
        province: this.province,
        city: this.city
      }
      // console.log('this========>', this.$http);
      this.$http('post', '/cooperation/server/doctor/get_clinic_doctors', params).then(res => {
        // console.log('找医生', res)
        this.loading = false;
        if (res.data.error == 0) {
          this.start_num = this.start_num + this.count;
          for (let i = 0; i < res.data.doctors.length; i++) {
            res.data.doctors[i].price = ((res.data.doctors[i].price / 100) * (1 + this.$store.state.floatRate)).toFixed(2);
          }
          this.doctor_list = this.doctor_list.concat(res.data.doctors);
          if (!res.data.doctors.length) {
            this.finished = true;
          }
          // if
        }
      })
    },
    // 搜索
    onSearch() {
      this.page = 1;
      this.doctor_list = [];
      this.onSearchList();
    },
    // 医生详情
    onDetail(id) {
      this.$router.push({ path: '/doctor_detail', query: { doctor_id: id } })
    },
    // 城市、科室格式化
    formatting() {
      // 全国省市格式化
      let province = JSON.parse(JSON.stringify(provinces.province));
      // console.log('格式化以前', province);
      for (let i = 0; i < province.length; i++) {
        for (let k = 0; k < province[i].children.length; k++) {
          province[i].children[k] = { text: province[i].children[k] }
        }
      }
      this.itemsCity = province;
      // console.log(this.itemsCity)
      // 科室格式化
      let department = globalData.getClinicList;
      for (let i = 0; i < department.length; i++) {
        department[i].text = department[i].clinic_name;
        department[i].children = department[i].second_clinic;
        for (let k = 0; k < department[i].children.length; k++) {
          department[i].children[k].text = department[i].children[k].second_clinic_name;
        }
      }
      this.departmentList = department;
    },
    /**
 * 省市二级联动
 *   {[onClickNav]}
 *  {[onClickItem]}   [description]
 */
    onClickNav(e) {
      // console.log(e)
      this.mainActiveIndex = e;
      this.province = this.itemsCity[e].text;
      // console.log(this.province);
      // this.$apply();
    },
    onClickItem(e) {
      // console.log(e)
      this.activeId = e.text;
      this.handleCity = e.text;
      this.city = e.text;
      // this.onSearchs();
      // console.log(this.$refs.city)
      this.$refs.city.showPopup = false;
      this.page = 1;
      this.doctor_list = [];
      this.onSearchList();
      // this.selectComponent('#item').toggle(false);
      // this.$apply();
    },
    /**
     * 选择科室
     * onClickLeft, onClickRight
     */
    onClickLeft(e) {
      this.departmentIndex = e;
      // this.$apply();
    },
    onClickRight(e) {
      this.department = e.text;
      this.query = e.text;
      this.departmentId = e.text;
      this.$refs.client.toggle()
      this.page = 1;
      this.doctor_list = [];
      this.onSearchList();
      // this.$wxpage.selectComponent('#department').toggle(false);
      // this.onSearchs();
      // this.$apply();
    },
  }
}
</script>
<style lang="less" scoped>
.search_img {
  width: 0.36rem;
  height: 0.38rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.doctor_list {
  background: #f8f8f8;
  min-height: 100%;
  .header {
    background: #fff;
    text-align: center;
    // padding-bottom: 0.2rem;
    width: 100%;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    p {
      font-size: 0.22rem;
      color: #999999;
    }
  }
}
.dropdown {
  background: #fff;
  // padding-top: 108rpx;
  border-bottom: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
  .van-dropdown-menu__title {
    font-size: 0.24rem;
    color: #333333;
  }
  .van-dropdown-menu {
    height: 0.6rem;
  }
  /deep/ .van-dropdown-menu__bar {
    box-shadow: none;
    height: 0.6rem;
  }
  /deep/ .van-dropdown-menu__title {
    font-size: 0.24rem;
    color: #333333;
  }
}
.list {
  padding-top: 2rem;
  // min-height: 800rpx;
  // margin-bottom: 20rpx;
}
.card {
  position: relative;
  display: flex;
  display: -webkit-flex;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  margin: 0 0.22rem 0.15rem;
  padding: 0.24rem;
  background: #fff;
  border-radius: 8px;
  .icon {
    margin-right: 0.22rem;
    width: 1.4rem;
    height: 1.4rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .info {
    flex: 1;
    -webkit-flex: 1;
    div {
      margin-bottom: 0.16rem;
    }
    .txt1 {
      font-size: 0.26rem;
      color: #333333;
      line-height: 0.3rem;
      span {
        font-size: 0.3rem;
        font-weight: bold;
      }
    }
    .txt2 {
      font-size: 0.24rem;
      line-height: 0.24rem;
      color: #666666;
    }
    .txt3 {
      font-size: 0.2rem;
      line-height: 0.28rem;
      color: #999999;
      width: 3.9rem;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      white-space: normal !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .txt4 {
      font-size: 0.22rem;
      line-height: 0.22rem;
      color: #666666;
      span {
        color: #ffc025;
      }
    }
    .sys_txt1 {
      font-size: 0.28rem;
      line-height: 0.28rem;
      color: #333333;
    }
    .sys_txt2 {
      font-size: 0.24rem;
      line-height: 0.24rem;
      color: #666666;
    }
    .sys_txt3 {
      font-size: 0.2rem;
      line-height: 0.2rem;
      color: #ffc025;
    }
  }
  .remark {
    .money {
      color: #ffc025;
      font-size: 0.2rem;
      text-align: center;
      span {
        font-size: 0.24rem;
        font-weight: bold;
      }
    }
  }
  .follow {
    text-align: center;
    margin-top: 0.5rem;
    img {
      width: 0.38rem;
      height: 0.38rem;
      // margin-bottom: 0.1rem;
    }
    p {
      color: #42cec8;
      font-size: 0.24rem;
    }
  }
}
</style>


